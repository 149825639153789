
import  Shepherd  from 'shepherd.js';
import Swal from 'sweetalert2'

export const startTour = () => {
  const tour = new Shepherd.Tour({
  defaultStepOptions: {
    cancelIcon: {
      enabled: true
    },
    classes: 'class-1 class-2',
    scrollTo: { behavior: 'smooth', block: 'center' }
  }
});
/* Start Walk Through */

// (intro)
tour.addStep({
  title: 'Welcome to Code Casually.',
  text: `Our editor has a lot of features. This tour covers the basics. You can stop it and start it over anytime.`,
  /*
  attachTo: {
    element: '.hero-example',
    on: 'bottom'
  },
  */
  buttons: [
    {
      action() {
        return this.back();
      },
      classes: 'shepherd-button-secondary',
      text: 'Back'
    },
    {
      action() {
        return this.next();
      },
      text: 'Next'
    }
  ],
  id: 'creating'
});
// (These are blocks)
tour.addStep({
  title: 'This is your main canvas. ',
  text: `You can drag-and-drop elements that we call "blocks" onto the canvas to create your HTML pages.`,

  attachTo: {
    element: 'body',
    on: 'top'
  },

  buttons: [
    {
      action() {
        return this.back();
      },
      classes: 'shepherd-button-secondary',
      text: 'Back'
    },
    {
      action() {
        return this.next();
      },
      text: 'Next'
    }
  ],
  id: 'creating'
});
// (These are blocks)
tour.addStep({
  title: 'These are blocks.',
  text: `You can drag-and-drop them into the canvas to create your structure and webpage content.`,

  attachTo: {
    element: '.cc-block-category',
    on: 'right'
  },

  buttons: [
    {
      action() {
        return this.back();
      },
      classes: 'shepherd-button-secondary',
      text: 'Back'
    },
    {
      action() {
        return this.next();
      },
      text: 'Next'
    }
  ],
  id: 'creating'
});

/*
tour.addStep({
  title: 'These are containers.',
  text: `Containers help control the layout of your content.`,

  attachTo: {
    element: '.cc-caret-icon',
    on: 'right'
  },

  buttons: [
    {
      action() {
        return this.back();
      },
      classes: 'shepherd-button-secondary',
      text: 'Back'
    },
    {
      action() {
        return this.next();
      },
      text: 'Next'
    }
  ],
  id: 'creating'
});

// (drag-and-drop sections)
tour.addStep({
  title: 'Sections. (we recommend starting with this.)',
  text: `Sections help organize content horizontally and help keep it justified when the screen size changes.  They work best with flex box's inside them.`,

  attachTo: {
    element: '.section123',
    on: 'right'
  },

  buttons: [
    {
      action() {
        return this.back();
      },
      classes: 'shepherd-button-secondary',
      text: 'Back'
    },
    {
      action() {
        return this.next();
      },
      text: 'Next'
    }
  ],
  id: 'creating'
});
// (Drag & Drop flex box)
tour.addStep({
  title: `Flex box is very customizable and versatile.`,
  text: `A flex box acts like a container for pretty much everything.  They work best when dropped into sections. You can change the default dimensions after you drop it.`,
  attachTo: {
    element: '.flex-box',
    on: 'right'
  },

  buttons: [
    {
      action() {
        return this.back();
      },
      classes: 'shepherd-button-secondary',
      text: 'Back'
    },
    {
      action() {
        return this.next();
      },
      text: 'Next'
    }
  ],
  id: 'creating'
});
*/

/* Start of the top bar intro */
// Step Two (devices)
tour.addStep({
  title: 'Device view.',
  text: `You'll want to make sure your website is responsive! You can preview and edit how your content will look on different devices.`,

  attachTo: {
    element: '.cc-pn-devices-c',
    on: 'bottom-start'
  },

  buttons: [
    {
      action() {
        return this.back();
      },
      classes: 'shepherd-button-secondary',
      text: 'Back'
    },
    {
      action() {
        return this.next();
      },
      text: 'Next'
    }
  ],
  id: 'creating'
});
// Step Three (view components)
tour.addStep({
  title: 'View all element borders that are in the canvas.',
  text: `This is turned on by default.  Turning it off hides the element borders and boundaries.`,

  attachTo: {
    element: '.fa-square-o',
    on: 'right'
  },

  buttons: [
    {
      action() {
        return this.back();
      },
      classes: 'shepherd-button-secondary',
      text: 'Back'
    },
    {
      action() {
        return this.next();
      },
      text: 'Next'
    }
  ],
  id: 'creating'
});
// Step Four (preview)
tour.addStep({
  title: 'Preview Webpage.',
  text: `This allows you to preview your webpage without the editor being visible.`,

  attachTo: {
    element: '.fa-eye',
    on: 'right'
  },

  buttons: [
    {
      action() {
        return this.back();
      },
      classes: 'shepherd-button-secondary',
      text: 'Back'
    },
    {
      action() {
        return this.next();
      },
      text: 'Next'
    }
  ],
  id: 'creating'
});

/*
//  (full Screen)
tour.addStep({
  title: 'Go Full Screen.',
  text: `This gives your screen more room by expanding the editor into fullscreen. You can turn this of by hitting the 'esc' key.`,

  attachTo: {
    element: '.fa-arrows-alt',
    on: 'right-start'
  },

  buttons: [
    {
      action() {
        return this.back();
      },
      classes: 'shepherd-button-secondary',
      text: 'Back'
    },
    {
      action() {
        return this.next();
      },
      text: 'Next'
    }
  ],
  id: 'creating'
});
*/
// Step six (undo)
tour.addStep({
  title: 'Undo.',
  text: `Undo any the last change you made.`,
  attachTo: {
    element: '.fa-undo',
    on: 'right-start'
  },

  buttons: [
    {
      action() {
        return this.back();
      },
      classes: 'shepherd-button-secondary',
      text: 'Back'
    },
    {
      action() {
        return this.next();
      },
      text: 'Next'
    }
  ],
  id: 'creating'
});
// Step seven (redo)
tour.addStep({
  title: 'Redo.',
  text: `Sometimes we undo things and go too far back.\
  This lets you undo the undo.`,

  attachTo: {
    element: '.fa-repeat',
    on: 'right-start'
  },

  buttons: [
    {
      action() {
        return this.back();
      },
      classes: 'shepherd-button-secondary',
      text: 'Back'
    },
    {
      action() {
        return this.next();
      },
      text: 'Next'
    }
  ],
  id: 'creating'
});

/*
// (import)
tour.addStep({
  title: 'Import HTML and CSS.',
  text: `Copy and paste your own HTML and CSS into the body of your webpage. CSS should be placed in a '<style></style>'.`,

  attachTo: {
    element: '.fa-download',
    on: 'right-start'
  },

  buttons: [
    {
      action() {
        return this.back();
      },
      classes: 'shepherd-button-secondary',
      text: 'Back'
    },
    {
      action() {
        return this.next();
      },
      text: 'Next'
    }
  ],
  id: 'creating'
});
*/

// (Delete)
tour.addStep({
  title: 'Clear the Canvas.',
  text: `This deletes everything in the canvas.`,

  attachTo: {
    element: '.fa-trash',
    on: 'right-start'
  },

  buttons: [
    {
      action() {
        return this.back();
      },
      classes: 'shepherd-button-secondary',
      text: 'Back'
    },
    {
      action() {
        return this.next();
      },
      text: 'Next'
    }
  ],
  id: 'creating'
});
/* End of the top bar intro */

/* Start of the side bar intro */
// (styles)
tour.addStep({
  title: 'Style Manager.',
  text: `Select an element in the canva and you can change it's style properties.`,
  attachTo: {
    element: '.cc-pn-views',
    on: 'right'
  },

  buttons: [
    {
      action() {
        return this.back();
      },
      classes: 'shepherd-button-secondary',
      text: 'Back'
    },
    {
      action() {
        return this.next();
      },
      text: 'Next'
    }
  ],
  id: 'creating'
});
// (traits)
tour.addStep({
  title: 'Attributes & Traits.',
  text: `Select an element to changes the attributes like 'id' or 'title'.  Some elements will have different attributes depending on their use.`,

  attachTo: {
    element: '.fa-cog',
    on: 'right'
  },

  buttons: [
    {
      action() {
        return this.back();
      },
      classes: 'shepherd-button-secondary',
      text: 'Back'
    },
    {
      action() {
        return this.next();
      },
      text: 'Next'
    }
  ],
  id: 'creating'
});
//  (stack)
tour.addStep({
  title: 'View the structure.',
  text: `As your design becomes more complex your elements will become nested.\
  This allows you to better manage everything visually.`,

  attachTo: {
    element: '.fa-bars',
    on: 'right'
  },

  buttons: [
    {
      action() {
        return this.back();
      },
      classes: 'shepherd-button-secondary',
      text: 'Back'
    },
    {
      action() {
        return this.next();
      },
      text: 'Next'
    }
  ],
  id: 'creating'
});
//  (blocks)
tour.addStep({
  title: 'This is the Block Manager.',
  text: `Click on this icon to bring up the blocks again.`,

  attachTo: {
    element: '.fa-th-large',
    on: 'right'
  },

  buttons: [
    {
      action() {
        return this.back();
      },
      classes: 'shepherd-button-secondary',
      text: 'Back'
    },
    {
      action() {
        return this.next();
      },
      text: 'Next'
    }
  ],
  id: 'creating'
});
/*
//  (code)
tour.addStep({
  title: 'Text Editor.',
  text: `A basic text editor for HTML and CSS.'\
  Select what you want in the canvas and then edit it's HTML and CSS directly.`,

  attachTo: {
    element: '.fa-file-code-o',
    on: 'right'
  },

  buttons: [
    {
      action() {
        return this.back();
      },
      classes: 'shepherd-button-secondary',
      text: 'Back'
    },
    {
      action() {
        return this.next();
      },
      text: 'Next'
    }
  ],
  id: 'creating'
});
*/
//  (Page)
tour.addStep({
  title: 'Page Manager.',
  text: `Create and manage your website pages.\
  You can add pages, delete pages, change the name of the page and select the page to edit in the canvas.`,

  attachTo: {
    element: '.fa-folder-o',
    on: 'right'
  },

  buttons: [
    {
      action() {
        return this.back();
      },
      classes: 'shepherd-button-secondary',
      text: 'Back'
    },
    {
      action() {
        return this.next();
      },
      text: 'Next'
    }
  ],
  id: 'creating'
});
/* End of the side bar intro */
//  (Menu)
tour.addStep({
  title: 'Explore cool stuff.',
  text: `You can securely save your project to the cloud and download your project in a zip file when you are logged into your free account.`,

  attachTo: {
    element: '.open-menu',
    on: 'right'
  },

  buttons: [
    {
      action() {
        return this.back();
      },
      classes: 'shepherd-button-secondary',
      text: 'Back'
    },
    {
      action() {
        return this.next();
      },
      text: 'Next'
    }
  ],
  id: 'creating'
});
// End Tour
tour.addStep({
  title: 'Start the tour anytime.',
  text: `You can start this tour over anytime by clicking on the "Help" button.`,

  attachTo: {
    element: '.getHelp',
    on: 'right'
  },

  buttons: [
    {
      action() {
        return this.back();
      },
      classes: 'shepherd-button-secondary',
      text: 'Back'
    },
    {
      action() {
        Swal.fire({
          backdrop: `
                rgba(0, 0, 0, 0.3)
              `,
          position: 'center',
          icon: 'success',
          title: "Great job. Have a wonderful day.",
          showConfirmButton: false,
          timer: 2000
        });
        return this.next();
      },
      text: 'Next'
    }
  ],
  id: 'creating'
});

tour.start();
}
