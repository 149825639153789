// @ts-check
import { initSchema } from '@aws-amplify/datastore';
import { schema } from './schema';



const { Template, Website, Profile } = initSchema(schema);

export {
  Template,
  Website,
  Profile
};