
// Amplify
import Amplify from "aws-amplify";
import { Auth } from 'aws-amplify';

// Sign Out function
export async function signOut() {
    // console.log("signOut triggered...")
    try {
        await Auth.userPool.getCurrentUser().signOut()
        Amplify.DataStore.clear();
        window.location = '/'
    } catch (error) {
        // console.log('error signing out: ', error);
        Amplify.DataStore.clear();
    }

}
