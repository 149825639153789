// Get the path
const urlPath = window.location.pathname;
import '../node_modules/grapesjs/dist/css/grapes.min.css';
import '/src/styles/editor.css';
import '/src/styles/index.css';
import '/src/styles/main.scss';
import grapesjs from 'grapesjs';
import elements from './components/elements.js';
import structure from './components/structure.js';
import typed from 'grapesjs-typed';
import projectManager from './editor/project-manager/src';
import indexeddb from 'grapesjs-indexeddb';
import pluginNavbar from './navbar';
import pluginCountdown from 'grapesjs-component-countdown';
import pluginForms from './formsPlugin';
import pluginExport from 'grapesjs-plugin-export';
import pluginFilestack from 'grapesjs-plugin-filestack';
import basicBlocks from "./basic_blocks";
import commands from './commands';
import codeEditor from 'grapesjs-component-code-editor';
import 'grapesjs-component-code-editor/dist/grapesjs-component-code-editor.min.css';
import './editor/project-manager/src/styles.scss';
import { hrefNextTrait } from './traits/index.js';
import panels from './panels';
import styles from './styles/index.js';
import grapesjsTouch from 'grapesjs-touch';
import pluginTooltip from 'grapesjs-tooltip';
import grapesjstabs from 'grapesjs-tabs';
import grapesjsCustomCode from 'grapesjs-custom-code';
import tUIImageEditor from 'grapesjs-tui-image-editor';
import scriptEditor from 'grapesjs-script-editor';
import 'grapesjs/dist/css/grapes.min.css';
import styleFilter from 'grapesjs-style-filter';
import blocks from './blocks';
import changeBlockIcons from './blocks';
import {Amplify, Auth, Storage} from "aws-amplify";
import aws_exports from "./aws-exports.js";
Amplify.configure(aws_exports);
import Swal from 'sweetalert2'
import { Tooltip,  Toast, Popover } from 'bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
// Copy paste
import {newCopy, newPaste} from './editor/copypaste.js';
import placeholder from './asset-manager/placeholder.png';
import asset1 from './asset-manager/1.jpg';
import asset2 from './asset-manager/2.jpg';
import asset3 from './asset-manager/3.jpg';
import asset4 from './asset-manager/4.jpg';
import asset5 from './asset-manager/5.jpg';
import asset6 from './asset-manager/6.jpg';
import asset7 from './asset-manager/7.jpg';
import asset8 from './asset-manager/8.jpg';
import asset9 from './asset-manager/9.jpg';
import asset10 from './asset-manager/10.jpg';
import asset11 from './asset-manager/11.jpg';
import asset12 from './asset-manager/12.jpg';
import asset13 from './asset-manager/13.jpg';
import asset14 from './asset-manager/14.jpg';
import asset15 from './asset-manager/15.jpg';
import asset16 from './asset-manager/16.jpg';
import asset17 from './asset-manager/17.jpg';
import asset18 from './asset-manager/18.jpg';
import asset19 from './asset-manager/19.jpg';
import asset20 from './asset-manager/20.jpg';
import asset21 from './asset-manager/21.jpg';
import asset22 from './asset-manager/22.jpg';
import asset23 from './asset-manager/23.jpg';
import asset24 from './asset-manager/24.jpg';
import asset25 from './asset-manager/25.jpg';
import asset26 from './asset-manager/26.jpg';
import asset27 from './asset-manager/27.jpg';
import asset28 from './asset-manager/28.jpg';
import asset29 from './asset-manager/29.jpg';
import asset30 from './asset-manager/30.jpg';
import asset31 from './asset-manager/31.jpg';
import asset32 from './asset-manager/32.jpg';
import asset33 from './asset-manager/33.jpg';
import asset34 from './asset-manager/34.jpg';
import asset35 from './asset-manager/35.jpg';
import asset36 from './asset-manager/36.jpg';
import asset37 from './asset-manager/37.jpg';
import asset38 from './asset-manager/38.jpg';
import asset39 from './asset-manager/39.jpg';
import asset40 from './asset-manager/40.jpg';
import asset41 from './asset-manager/41.jpg';
import asset42 from './asset-manager/42.jpg';
import asset43 from './asset-manager/43.jpg';
import asset44 from './asset-manager/44.jpg';
import asset45 from './asset-manager/45.jpg';
import asset46 from './asset-manager/46.jpg';
import asset47 from './asset-manager/47.jpg';
import asset48 from './asset-manager/48.jpg';
import asset49 from './asset-manager/49.jpg';
import asset50 from './asset-manager/50.jpg';
import asset51 from './asset-manager/51.jpg';
import asset52 from './asset-manager/52.jpg';
import asset53 from './asset-manager/53.jpg';
import asset54 from './asset-manager/54.jpg';
import asset55 from './asset-manager/55.jpg';
import asset56 from './asset-manager/56.jpg';
import asset57 from './asset-manager/57.jpg';
import asset58 from './asset-manager/58.jpg';
import asset59 from './asset-manager/59.jpg';
import asset60 from './asset-manager/60.jpg';
import asset61 from './asset-manager/61.jpg';
import asset62 from './asset-manager/62.jpg';
import asset63 from './asset-manager/63.jpg';
import asset64 from './asset-manager/64.jpg';
import asset65 from './asset-manager/65.jpg';
import asset66 from './asset-manager/66.jpg';
import asset67 from './asset-manager/67.jpg';
import asset68 from './asset-manager/68.jpg';
import asset69 from './asset-manager/69.jpg';
import asset70 from './asset-manager/70.jpg';
import asset71 from './asset-manager/71.jpg';
import asset72 from './asset-manager/72.jpg';
import asset73 from './asset-manager/73.jpg';
import asset74 from './asset-manager/74.jpg';
import asset75 from './asset-manager/75.jpg';
import asset76 from './asset-manager/76.jpg';
import asset77 from './asset-manager/77.jpg';

export default grapesjs.plugins.add('cc-preset-webpage', (editor, opts = {}) => {
  let config = opts;
  let defaults = {
    // Which blocks to add
    blocks: [],
    // Modal import title
    modalImportTitle: 'Import',
    // Modal import button text
    modalImportButton: 'Import',
    // Import description inside import modal
    modalImportLabel: '',
    // Default content to setup on import model open.
    // Could also be a function with a dynamic content return (must be a string)
    // eg. modalImportContent: editor => editor.getHtml(),
    modalImportContent: '',
    // Code viewer (eg. CodeMirror) options
    // importViewerOptions: {},
    // Confirm text before cleaning the canvas
    textCleanCanvas: 'Are you sure to clean the canvas?',
    // Show the Style Manager on component change
    showStylesOnChange: 1,
    customStyleManager: [{
        sectors: [{
          name: 'Dimension',
          properties: [

          ],
        }, ]
      },

    ],
    // `grapesjs-component-countdown` plugin options
    // By setting this option to `false` will avoid loading the plugin
    countdownOpts: {},
    // `grapesjs-plugin-forms` plugin options
    // By setting this option to `false` will avoid loading the plugin
    formsOpts: {},
    // `grapesjs-plugin-export` plugin options
    // By setting this option to `false` will avoid loading the plugin
    exportOpts: {},
    // `grapesjs-aviary` plugin options, disabled by default
    // Aviary library should be included manually
    // By setting this option to `false` will avoid loading the plugin
    aviaryOpts: {},
    // `grapesjs-plugin-filestack` plugin options, disabled by default
    // Filestack library should be included manually
    // By setting this option to `false` will avoid loading the plugin
    filestackOpts: 0,
    // `grapesjs-navbar` plugin options
    // By setting this option to `false` will avoid loading the plugin
    navbarOpts: {},
  };
  // Load defaults
  for (let name in defaults) {
    if (!(name in config))
      config[name] = defaults[name];
  }
  const {
    countdownOpts,
    formsOpts,
    exportOpts,
    aviaryOpts,
    formOpts,
    filestackOpts,
    navbarOpts,
  } = config;
  // Load plugins
  navbarOpts && pluginNavbar(editor, navbarOpts);
  // Load components
  elements(editor, config);
  // Load blocks
  structure(editor, config);

  blocks(editor, config);
  // Load components
  countdownOpts && pluginCountdown(editor, countdownOpts);
  formsOpts && pluginForms(editor, formsOpts);
  exportOpts && pluginExport(editor, exportOpts);
  // aviaryOpts && pluginAviary(editor, aviaryOpts);
  filestackOpts && pluginFilestack(editor, filestackOpts);
  // Load commands
  commands(editor, config);
  // Load panels
  panels(editor, config);
  // Load styles
  styles(editor, config);
});

export const editor = grapesjs.init({

  height: '100%',
  pageManager: true, // This should be set to true
  showOffsets: 1,
  noticeOnUnload: 0,
  assetManager: {
  assets: [
  placeholder,
  // Pass an object with your properties
  {
    type: 'image',
    src: asset1,
    height: 350,
    width: 250,
    name: 'background-1'
  },
  // Pass an object with your properties
  {
    type: 'image',
    src: asset2,
    height: 350,
    width: 250,
    name: 'background-2'
  },
  {
    type: 'image',
    src: asset3,
    height: 350,
    width: 250,
    name: 'background-3'
  },
  {
    type: 'image',
    src: asset4,
    height: 350,
    width: 250,
    name: 'background-4'
  },
  {
    type: 'image',
    src: asset5,
    height: 350,
    width: 250,
    name: 'background-5'
  },
  {
    type: 'image',
    src: asset6,
    height: 350,
    width: 250,
    name: 'background-6'
  },
  // Pass an object with your properties
  {
    type: 'image',
    src: asset7,
    height: 350,
    width: 250,
    name: 'background-7'
  },
  // Pass an object with your properties
  {
    type: 'image',
    src: asset8,
    height: 350,
    width: 250,
    name: 'background-8'
  },
  // Pass an object with your properties
  {
    type: 'image',
    src: asset9,
    height: 350,
    width: 250,
    name: 'background-9'
  },
  {
    type: 'image',
    src: asset10,
    height: 350,
    width: 250,
    name: 'background-10'
  },
  {
    type: 'image',
    src: asset11,
    height: 350,
    width: 250,
    name: 'background-11'
  },
  {
    type: 'image',
    src: asset12,
    height: 350,
    width: 250,
    name: 'background-12'
  },
  {
    type: 'image',
    src: asset13,
    height: 350,
    width: 250,
    name: 'background-13'
  },
  {
    type: 'image',
    src: asset14,
    height: 350,
    width: 250,
    name: 'background-14'
  },
  {
    type: 'image',
    src: asset15,
    height: 350,
    width: 250,
    name: 'background-15'
  },
  {
    type: 'image',
    src: asset16,
    height: 350,
    width: 250,
    name: 'background-16'
  },
  {
    type: 'image',
    src: asset17,
    height: 350,
    width: 250,
    name: 'background-17'
  },
  {
    type: 'image',
    src: asset18,
    height: 350,
    width: 250,
    name: 'background-18'
  },
  {
    type: 'image',
    src: asset19,
    height: 350,
    width: 250,
    name: 'background-19'
  },
  {
    type: 'image',
    src: asset20,
    height: 350,
    width: 250,
    name: 'background-20'
  },
  {
    type: 'image',
    src: asset21,
    height: 350,
    width: 250,
    name: 'background-21'
  },
  {
    type: 'image',
    src: asset22,
    height: 350,
    width: 250,
    name: 'background-22'
  },
  {
    type: 'image',
    src: asset23,
    height: 350,
    width: 250,
    name: 'background-23'
  },
  {
    type: 'image',
    src: asset24,
    height: 350,
    width: 250,
    name: 'background-24'
  },
  {
    type: 'image',
    src: asset25,
    height: 350,
    width: 250,
    name: 'background-25'
  },
  {
    type: 'image',
    src: asset26,
    height: 350,
    width: 250,
    name: 'background-26'
  },
  {
    type: 'image',
    src: asset27,
    height: 350,
    width: 250,
    name: 'background-27'
  },
  {
    type: 'image',
    src: asset28,
    height: 350,
    width: 250,
    name: 'background-28'
  },
  {
    type: 'image',
    src: asset29,
    height: 350,
    width: 250,
    name: 'background-29'
  },
  {
    type: 'image',
    src: asset30,
    height: 350,
    width: 250,
    name: 'background-30'
  },
  {
    type: 'image',
    src: asset31,
    height: 350,
    width: 250,
    name: 'background-31'
  },
  {
    type: 'image',
    src: asset32,
    height: 350,
    width: 250,
    name: 'background-32'
  },
  {
    type: 'image',
    src: asset33,
    height: 350,
    width: 250,
    name: 'background-33'
  },
  {
    type: 'image',
    src: asset34,
    height: 350,
    width: 250,
    name: 'background-34'
  },
  {
    type: 'image',
    src: asset35,
    height: 350,
    width: 250,
    name: 'background-35'
  },
  {
    type: 'image',
    src: asset36,
    height: 350,
    width: 250,
    name: 'background-36'
  },
  {
    type: 'image',
    src: asset37,
    height: 350,
    width: 250,
    name: 'background-37'
  },
  {
    type: 'image',
    src: asset38,
    height: 350,
    width: 250,
    name: 'background-38'
  },
  {
    type: 'image',
    src: asset39,
    height: 350,
    width: 250,
    name: 'background-39'
  },
  {
    type: 'image',
    src: asset40,
    height: 350,
    width: 250,
    name: 'background-40'
  },
  {
    type: 'image',
    src: asset41,
    height: 350,
    width: 250,
    name: 'background-41'
  },
  {
    type: 'image',
    src: asset42,
    height: 350,
    width: 250,
    name: 'background-42'
  },
  {
    type: 'image',
    src: asset43,
    height: 350,
    width: 250,
    name: 'background-43'
  },
  {
    type: 'image',
    src: asset44,
    height: 350,
    width: 250,
    name: 'background-44'
  },
  {
    type: 'image',
    src: asset45,
    height: 350,
    width: 250,
    name: 'background-45'
  },
  {
    type: 'image',
    src: asset46,
    height: 350,
    width: 250,
    name: 'background-46'
  },
  {
    type: 'image',
    src: asset47,
    height: 350,
    width: 250,
    name: 'background-47'
  },
  {
    type: 'image',
    src: asset48,
    height: 350,
    width: 250,
    name: 'background-48'
  },
  {
    type: 'image',
    src: asset49,
    height: 350,
    width: 250,
    name: 'background-49'
  },
  {
    type: 'image',
    src: asset50,
    height: 350,
    width: 250,
    name: 'background-50'
  },
  {
    type: 'image',
    src: asset51,
    height: 350,
    width: 250,
    name: 'background-51'
  },
  {
    type: 'image',
    src: asset52,
    height: 350,
    width: 250,
    name: 'background-52'
  },
  {
    type: 'image',
    src: asset53,
    height: 350,
    width: 250,
    name: 'background-53'
  },
  {
    type: 'image',
    src: asset54,
    height: 350,
    width: 250,
    name: 'background-54'
  },
  {
    type: 'image',
    src: asset55,
    height: 350,
    width: 250,
    name: 'background-55'
  },
  {
    type: 'image',
    src: asset56,
    height: 350,
    width: 250,
    name: 'background-56'
  },
  {
    type: 'image',
    src: asset57,
    height: 350,
    width: 250,
    name: 'background-57'
  },
  {
    type: 'image',
    src: asset58,
    height: 350,
    width: 250,
    name: 'background-58'
  },
  {
    type: 'image',
    src: asset59,
    height: 350,
    width: 250,
    name: 'background-59'
  },
  {
    type: 'image',
    src: asset60,
    height: 350,
    width: 250,
    name: 'background-60'
  },
  {
    type: 'image',
    src: asset61,
    height: 350,
    width: 250,
    name: 'background-61'
  },
  {
    type: 'image',
    src: asset62,
    height: 350,
    width: 250,
    name: 'background-62'
  },
  {
    type: 'image',
    src: asset63,
    height: 350,
    width: 250,
    name: 'background-63'
  },
  {
    type: 'image',
    src: asset64,
    height: 350,
    width: 250,
    name: 'background-64'
  },
  {
    type: 'image',
    src: asset65,
    height: 350,
    width: 250,
    name: 'background-65'
  },
  {
    type: 'image',
    src: asset66,
    height: 350,
    width: 250,
    name: 'background-66'
  },
  {
    type: 'image',
    src: asset67,
    height: 350,
    width: 250,
    name: 'background-67'
  },
  {
    type: 'image',
    src: asset68,
    height: 350,
    width: 250,
    name: 'background-68'
  },
  {
    type: 'image',
    src: asset69,
    height: 350,
    width: 250,
    name: 'background-69'
  },
  {
    type: 'image',
    src: asset70,
    height: 350,
    width: 250,
    name: 'background-70'
  },
  {
    type: 'image',
    src: asset71,
    height: 350,
    width: 250,
    name: 'background-71'
  },
  {
    type: 'image',
    src: asset72,
    height: 350,
    width: 250,
    name: 'background-72'
  },
  {
    type: 'image',
    src: asset73,
    height: 350,
    width: 250,
    name: 'background-73'
  },
  {
    type: 'image',
    src: asset74,
    height: 350,
    width: 250,
    name: 'background-74'
  },
  {
    type: 'image',
    src: asset75,
    height: 350,
    width: 250,
    name: 'background-75'
  },
  {
    type: 'image',
    src: asset76,
    height: 350,
    width: 250,
    name: 'background-76'
  },
  {
    type: 'image',
    src: asset77,
    height: 350,
    width: 250,
    name: 'background-77'
  },
 ],
},
  storageManager: {
    // Default storage type. Available: local | remote
    type: 'indexeddb',
    // Enable/Disable autosaving
    autosave: true,
    // Enable/Disable autoload of data on editor init
    autoload: true,
  },
  selectorManager: {},
  container: '#cc',
  fromElement: true,
  plugins: [grapesjsTouch, tUIImageEditor, styleFilter, 'cc-preset-webpage', basicBlocks, codeEditor, scriptEditor, typed, pluginTooltip, grapesjstabs, /*grapesjsCustomCode*/ , projectManager, indexeddb, hrefNextTrait],
  pluginsOpts: {
    indexeddb: {
      options: {
        // In case of multiple projects on the same page indicate an id to
        // prevent collisions
        key: 'project-id',
        // Update IndexedDB name for the DB and the table containing project data
        dbName: 'editorLocalData',
        objectStoreName: 'projects',
      }
    },
    basicBlocks: {},
    projectManager: {},
    codeEditor: {},
    scriptEditor: {},
    'cc-preset-webpage': {},
    pluginTooltip: {},
    typed: {},
    grapesjstabs: {},
    grapesjsCustomCode: {},
    [tUIImageEditor]: {
      config: {
        includeUI: {
          initMenu: 'filter'
        }
      },
      icons: {
        'menu.normalIcon.path': `../node_modules/tui-image-editor/dist/svg/icon-d.svg`,
        'menu.activeIcon.path': `../node_modules/tui-image-editor/dist/svg/icon-b.svg`,
        'menu.disabledIcon.path': `../node_modules/tui-image-editor/dist/svg/icon-a.svg`,
        'menu.hoverIcon.path': `../node_modules/tui-image-editor/dist/svg/icon-c.svg`,
        'submenu.normalIcon.path': `../node_modules/tui-image-editor/dist/svg/icon-d.svg`,
        'submenu.activeIcon.path': `../node_modules/tui-image-editor/dist/svg/icon-c.svg`,
      },
      script: [
        'https://uicdn.toast.com/tui.code-snippet/v1.5.2/tui-code-snippet.min.js',
        'https://uicdn.toast.com/tui-color-picker/v2.2.7/tui-color-picker.min.js',
        'https://uicdn.toast.com/tui-image-editor/v3.15.2/tui-image-editor.min.js'
      ],
      style: [
        'https://uicdn.toast.com/tui-color-picker/v2.2.7/tui-color-picker.min.css',
        'https://uicdn.toast.com/tui-image-editor/v3.15.2/tui-image-editor.min.css',
      ],
    },
    styleFilter: {},
    canvas: {
        styles: [
          'https://stackpath.bootstrapcdn.com/bootstrap/4.1.3/css/bootstrap.min.css'
        ],
        scripts: [
          'https://code.jquery.com/jquery-3.3.1.slim.min.js',
          'https://cdnjs.cloudflare.com/ajax/libs/popper.js/1.14.3/umd/popper.min.js',
          'https://stackpath.bootstrapcdn.com/bootstrap/4.1.3/js/bootstrap.min.js',
        ],

      },
  },
});
/*
// Save the style of selected componentto local storage.
editor.on('component:selected', (component) => {
  const domElement = component.getEl();
  const style = window.getComputedStyle(domElement)
  localStorage.setItem("selectedCompStyle", JSON.stringify(style));
});
*/


// Use the API
const keymaps = editor.Keymaps;
console.log(`These are the keymaps ${JSON.stringify(keymaps.getAll())}`);

 editor.Keymaps.remove('core:copy');
 editor.Keymaps.remove('core:paste');
// 'ns' is just a custom namespace
keymaps.add('newCopy', 'ctrl+c', editor => {
  newCopy(editor);
});
// 'ns' is just a custom namespace
keymaps.add('paste', 'ctrl+v', editor => {
newPaste(editor);
});


const pn = editor.Panels;
const panelViews = pn.addPanel({
  id: 'views'
});



/*
panelViews.get('buttons').add([{
  attributes: {
    title: 'Open Code'
  },
  className: 'fa fa-file-code-o',
  command: 'open-code',
  togglable: false, //do not close when button is clicked again
  id: 'open-code'
}]);
*/

pn.addButton('views', {
  id: 'open-templates',
  className: 'fa fa-folder-o',
  attributes: {
    title: 'Open pages.'
  },
  command: 'open-pages', //Open modal
});



const closeAllBlockCateogries = (editor) => {
  let categories = editor.BlockManager.getCategories();
categories.each(category => {
	category.set('open', false).on('change:open', opened => {
		opened.get('open') && categories.each(category => {
            category !== opened && category.set('open', false)
        })
	})
})
/*
  let blocks = editor.Blocks.getAll();
  let categories = [];
  blocks.forEach((block) => {
    let categoryLabel = block.getCategoryLabel();
    categories.push(categoryLabel);
  });
  */
  console.log(`Categories: ${JSON.stringify(categories)}`);
};

closeAllBlockCateogries(editor);
