import Swal from 'sweetalert2'
import grapesjs from 'grapesjs';
import { editor } from '../editor.js';
import { isArray, contains } from 'underscore';

export const newCopy = (ed) => {
  const em = ed.getModel();
  const models = [...ed.getSelectedAll()];
  models.length && em.set('clipboard', models);
  models.length && localStorage.setItem('cc-clipboard', JSON.stringify(models));
  Swal.fire({
    backdrop: `
        rgba(0, 0, 0, 0.3)
      `,
    position: 'top-end',
    icon: 'success',
    title: "Copied.",
    showConfirmButton: false,
    timer: 750
  });
}



export const newPaste = (ed, s) => {
    const em = ed.getModel();
    const clp = em.get('clipboard');
    let opts = {};
    const lastSelected = JSON.parse(localStorage.getItem('cc-clipboard'));
    if (clp && lastSelected) {
          ed.getSelectedAll().forEach(selected => {
            const { collection } = selected;
            if (!collection){
              Swal.fire({
                backdrop: `
                    rgba(0, 0, 0, 0.3)
                  `,
                position: 'top-end',
                icon: 'error',
                title: "Nothing selected.",
                html: 'This page needs to have one element selected.',
                showConfirmButton: false,
                timer: 2000
              });
              return;
            }

            let added;
            const at = selected.index() + 1;
            const addOpts = { at, action: opts.action || 'paste-component' };

            if (contains(clp, selected) && selected.get('copyable')) {
              added = collection.add(selected.clone(), addOpts);
            } else {
              const copyable = clp.filter(cop => cop.get('copyable'));
              const pasteable = copyable.filter(cop => ed.Components.canMove(selected.parent(), cop).result);
              added = collection.add(
                pasteable.map(cop => cop.clone()),
                addOpts
              );
            }

            added = isArray(added) ? added : [added];
            added.forEach(add => ed.trigger('component:paste', add));
            Swal.fire({
              backdrop: `
                  rgba(0, 0, 0, 0.3)
                `,
              position: 'top-end',
              icon: 'success',
              title: "Pasted.",
              showConfirmButton: false,
              timer: 750
            });
          });


      // lastSelected.emitUpdate();
    } else{
      Swal.fire({
        backdrop: `
            rgba(0, 0, 0, 0.3)
          `,
        position: 'top-end',
        icon: 'error',
        title: "Oops",
        html: 'Select something and press "ctrl + c". Select something else and press "ctrl + v".',
        showConfirmButton: false,
        timer: 2000
      });
    }
  }
