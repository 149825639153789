import navigation from './html/navigation.html';
import footer from './html/footer.html';
import './fonts/stylesheet.css';
// Amplify
import {Amplify, Auth, Storage } from "aws-amplify";
import aws_exports from "./aws-exports.js";
Amplify.configure(aws_exports);
// API
import { DataStore } from '@aws-amplify/datastore';
// Auth
import {  userAuthState } from './auth/auth_user';
import {  checkAuthContent } from './auth/auth_content';
import {  signUp,  confirmSignUp,  resendConfirmationCode } from './auth/auth_signup';
import {  signIn } from './auth/auth_login';
import {  forgotPass,  confirmForgotPass } from './auth/auth_forgot_password';
import {  signOut } from './auth/auth_logout';
// API
import { updateWebsite } from './api/website-update';
Amplify.configure(aws_exports);
import grapesjs from 'grapesjs';
import { editor } from './editor.js';
import { clearIndexDB } from './editor/projectdata.js';
import { showTemplates } from './api/website-query.js';
import { createwebsite } from './api/website-create.js';
import { deleteItem } from './api/website-delete.js';
import { showWebsites } from './api/website-query.js';
import { updateProjectData, updateThumbnail, getThumbnail } from './api/website-update.js';
import { getProjectDataForEditor, getTemplateDataForEditor } from './editor/projectdata.js';
// Download Porject
import { downloadProject, downloadProjectFromLocal } from "./editor/exportProject.js";
// Images
import micahLogo from './images/logo.png';
import webpackIcon from './images/icon-square-big.png';
import logo from './images/micah.png'
import blocksGraphic from './images/blocks.jpeg';
import styleGraphic from './images/styleGraphic.jpeg';
import responsiveGraphic from './images/responsive.jpeg';
import imageGraphic from './images/images.jpeg';
// import codeGraphic from './images/codefeature.jpeg';
import structureGraphic from './images/structurefeature.jpeg';
import template1image from './images/template1.png';
import template2image from './images/template2.png';
import paidOneImage from './images/paid1.jpeg';
import paidTwoImage from './images/paid2.jpeg';
import paidThreeImage from './images/paid3.jpeg';
import thumbnail from './images/thumbnail.png';
import githubPicture from './images/github.png';
import AOS1 from '../node_modules/aos';
import AOS from 'aos';
import Swal from 'sweetalert2'
import Shepherd from 'shepherd.js';
import './styles/shepherd.css';
import './styles/copypaste.css';
// Onboarding;
import { startTour } from './tour.js';
// Get the path
const urlPath = window.location.pathname;
// Videos
import intro from './videos/intro.mp4';
import createit from './videos/createit.mp4';
import downloadit from './videos/downloadit.mp4';
import uploadit from './videos/uploadit.mp4';
import learnIntro from './videos/learnintro.mp4';
/* THIS IS A BUG: The website is adding the footer element twice when the path is '' || '/'. I do not know why.
This started to happen after I implemented the HTML Webpack Plugin */
//if (urlPath === "" || urlPath === "/") { window.location.pathname = 'index.html'; }
/* THIS IS A BUG: The website is adding the footer element twice when the path is '' || '/'. I do not know why.
This started to happen after I implemented the HTML Webpack Plugin */
if (urlPath != "/editor.html") {
  const body = document.body;
  const page = navigation + body.innerHTML + footer;
  body.innerHTML = page;
  // Set page content with nav and footer
  const navLogo = document.getElementById('nav-logo');
  // Change the copyright
  const copyRight = document.getElementById('copyright'); // Auto Update Copyright
  const currentYear = new Date().getFullYear();
  copyRight.innerHTML = 'Copyright Code Casually ©' + currentYear;
  navLogo.src = logo;
  // Get buttons and images
  const loginButton = document.getElementById('nav-login');
  const logoutButton = document.getElementById('nav-logout');
  const signupButton = document.getElementById('nav-signup');
  userAuthState()
    .then(data => {
      loginButton.style.display = "none";
      signupButton.style.display = "none";
      // Event Listener for Sign Out button
      if (document.querySelector("#nav-logout")) {
        document.querySelector("#nav-logout").addEventListener("click", () => {
          signOut();
        })
      };
      if (urlPath != "/dashboard.html" && urlPath != "/github_auth.html") {
        window.location.pathname = "/dashboard.html";
      } if (urlPath == '/github_auth.html') {
        const gh = document.getElementById("githubImage");
        gh.src = githubPicture;
        getTokenfromCode();
      } else {
        window.scrollTo(0, 0);
        // DOM
        const websiteList = document.getElementById('websiteRG');
        const createItemButton = document.getElementById('createwebsiteButton');
        const websiteTitleInput = document.getElementById('websiteName');
        const websiteDescriptionInput = document.getElementById('websiteDescription');
        const closewebsiteModal = document.getElementById('closewebsiteModal');
        const websiteTitleInputUpdate = document.getElementById('websiteNameUpdate');
        const websiteDescriptionInputUpdate = document.getElementById('updateWebsiteDescriptionUpdate');
        const udpateWebsiteButton = document.getElementById('updateWebsiteButton');
        const deleteMe = document.getElementById('deleteME');
        const homeNavlink = document.getElementById('homeNavlink'); // Navigation
        const createNavlink = document.getElementById('createNavlink'); // Navigation
        const docsNavlink = document.getElementById('docsNavlink'); // Navigation
        docsNavlink.style.display = "none";
        const learnNavlink = document.getElementById('learnNavlink'); // Navigation
        // const  = document.getElementById('getGitHubCode'); // Github
        // Hide these navigation options when logged in
        learnNavlink.style.display = "none";
        createNavlink.style.display = "none";
        homeNavlink.style.display = "none";
        // Buttons and even Listeners
        /*
        connectGithubButton.addEventListener("click", function() {
        getGitHubCode();
        });
        */
        createItemButton.addEventListener("click", function() {
          console.log("The create website button was clicked");
          const site = createwebsite(websiteTitleInput.value, websiteDescriptionInput.value, false);
          site.then(data => {
              console.log(data);
            })
            .catch(error => {
              console.error(error);
            });
          websiteTitleInput.value = "";
          websiteDescriptionInput.value = "";
        });
        closewebsiteModal.addEventListener("click", function() {
          websiteTitleInput.value = "";
          websiteDescriptionInput.value = "";
        });
        updateWebsiteButton.addEventListener("click", function() {
          updateWebsite(this.dataset.id, websiteTitleInputUpdate.value, websiteDescriptionInputUpdate.value, false);
          websiteTitleInputUpdate.value = "";
          websiteDescriptionInputUpdate.value = "";
        });
        showWebsites();
        setTimeout(showWebsites, 2000);
      };
    })
    .catch(error => {
      if (logoutButton) {
        logoutButton.style.display = "none";
      };
      if (signupButton) {
        signupButton.style.display = "none";
      };
      // Event Listeners if user is on Account confirmation page
      if (document.querySelector("#auth-signup-confirm")) {
        // Populate the email address value
        let username_value = location.hash.substring(1);
        document.querySelector("#formSignUpConfirmEmail").setAttribute("value", username_value);
        document.querySelector("#form-auth-signup-confirm").addEventListener("click", event => {
          event.preventDefault();
        });
        document.querySelector("#btnConfirm").addEventListener("click", () => {
          let username = document.querySelector("#formSignUpConfirmEmail").value
          const code = document.querySelector("#formSignUpConfirmCode").value
          console.log({
            username,
            code
          });
          confirmSignUp({
            username,
            code
          });
        });
        document.querySelector("#btnResend").addEventListener("click", () => {
          let username = document.querySelector("#formSignUpConfirmEmail").value
          resendConfirmationCode(username);
        });
      };
      // Event Listeners if user is on the Sign Up page
      if (document.querySelector("#auth-signup")) {
        document.querySelector("#form-auth-signup").addEventListener("submit", event => {
          event.preventDefault(); // Prevent the browser from reloading on submit event.
        });
        document.querySelector("#btnSignUp").addEventListener("click", () => {
          const email = document.querySelector("#formSignUpEmail").value
          const password = document.querySelector("#formSignUpPassword").value
          signUp({
            email,
            password
          });
        });
      };
      // Event Listeners if user is on Login page
      if (document.querySelector("#auth-login")) {
        document.querySelector("#form-auth-login").addEventListener("click", event => {
          event.preventDefault();
        });
        document.querySelector("#btnLogin").addEventListener("click", () => {
          console.log("The login button has fired.");
          const username = document.querySelector("#formLoginEmail").value
          const password = document.querySelector("#formLoginPassword").value
          signIn({
            username,
            password
          });
        });
      };
      // Event Listeners on the Confirm New Password page (after Forgot Password page)
      if (document.querySelector("#auth-forgot-password-confirm")) {
        // Populate the email address value
        let username_value = location.hash.substring(1);
        document.querySelector("#formForgotConfirmEmail").setAttribute("value", username_value);
        document.querySelector("#form-auth-forgot-password-confirm").addEventListener("click", event => {
          event.preventDefault();
        });
        document.querySelector("#btnConfirmForgot").addEventListener("click", () => {
          const username = document.querySelector("#formForgotConfirmEmail").value
          let code = document.querySelector("#formForgotConfirmCode").value
          let password = document.querySelector("#formForgotConfirmPassword").value
          confirmForgotPass(username, code, password);
        });
      };
      // Event Listeners if user is on Forgot Password page
      if (document.querySelector("#auth-forgot-password")) {
        document.querySelector("#form-auth-forgot-password").addEventListener("click", event => {
          event.preventDefault();
        });
        document.querySelector("#btnForgot").addEventListener("click", () => {
          const username = document.querySelector("#formForgotEmail").value
          forgotPass({
            username
          });
        });
      };
    });
  switch (urlPath) {
    case '':
    case '/':
    case '/index.html':
    // This is a bug were the footer is showing up twice on the home page.
    const footerArray = document.getElementsByTagName("footer");
    footerArray[1].style.display = "none";
    // This is a bug were the footer is showing up twice on the home page.
    const headLogo = document.getElementById("micahLogo");
    headLogo.src = micahLogo;
      const indexLogoTop = document.getElementById("indexLogoTop");
      const template1 = document.getElementById("template1");
      const template2 = document.getElementById("template2");
      const template1frame = document.getElementById("template1image");
      const template2frame = document.getElementById("template2image");
      const learnIntroVid = document.getElementById("intro-video");
      const createItVideo = document.getElementById("create-video");
      const downloadItVideo = document.getElementById("download-video");
      const uploadItVideo = document.getElementById("upload-video");
      learnIntroVid.src = intro;
      createItVideo.src = createit;
      downloadItVideo.src = downloadit;
      uploadItVideo.src = uploadit;
      template1frame.src = template1image;
      template2frame.src = template2image;
      template1.addEventListener("click", function() {
        clearIndexDB();
        localStorage.setItem("ccProjectID", "DEMO");
        window.location = '/editor.html';
      });
      template2.addEventListener("click", function() {
        clearIndexDB();
        localStorage.setItem("ccProjectID", "");
        window.location = '/signup.html';
      });
      indexLogoTop.src = logo;
      break;
    case '/create_a_website_for_free_with_code_casually.html':
      const tryWithoutButton = document.getElementById('tryWithoutButton');
      tryWithoutButton.addEventListener('click', function() {
        clearIndexDB();
        localStorage.setItem("ccProjectID", "DEMO");
        window.location = '/editor.html';
      });
      const structureFeature = document.getElementById('structureFeature');
      // const codeFeature = document.getElementById("codeFeature");
      const diyLogo = document.getElementById("diyLogo");
      const blocksFeature = document.getElementById("blocksFeature");
      const styleFeature = document.getElementById("styleFeature");
      const responsiveFeature = document.getElementById("responsiveFeature");
      const imageFeature = document.getElementById("imageFeature");
//      const indexLogoTop = document.getElementById("indexLogoTop");
      structureFeature.src = structureGraphic;
    //  codeFeature.src = codeGraphic;
      imageFeature.src = imageGraphic;
      styleFeature.src = styleGraphic;
      blocksFeature.src = blocksGraphic;
      responsiveFeature.src = responsiveGraphic;
      diyLogo.src = micahLogo;
    //  indexLogoTop.src = logo;
      break;
    case '/dashboard.html':
      clearIndexDB();
      break;
    case '/learning.html':
    const introVideo = document.getElementById('intro-video');
    introVideo.src = learnIntro;

      break;
      /*
    case '/hire_a_pro.html':
      const paidTwo = document.getElementById("paidTwo");
      const paidOne = document.getElementById("paidOne");
      const paidThree = document.getElementById("paidThree");
      const headLogo = document.getElementById("micahLogo");
      const webpackIconDom = document.getElementById("webpack");
      headLogo.src = micahLogo;
      paidOne.src = paidOneImage;
      paidTwo.src = paidTwoImage;
      paidThree.src = paidThreeImage;
      webpackIconDom.src = webpackIcon;
      break;
      */
    case '/github_auth.html':
      const gh = document.getElementById("githubImage");
      gh.src = githubPicture;
      getTokenfromCode();
    break;
    default:
      console.log(`Sorry, we are out of ${expr}.`);
  }
} else {
  const body = document.body;
  const page = body.innerHTML;

  //Download Project
  // DOM object and listener
  const navLogo = document.getElementById('nav-logo');
  navLogo.src = logo;
  const uploadThumbnailButton = document.getElementById('thumbnailUploadButton');
  uploadThumbnailButton.addEventListener("click", function() {
    userAuthState()
      .then(data => {
        const inputThumbnail = document.getElementById('thumbnailInput');
        // Test
        let file = inputThumbnail.files[0];
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
         let string = reader.result
         updateThumbnail(string);
         inputThumbnail.value = null;
        };
        reader.onerror = function (error) {
          console.log('Error: ', error);
        };
      })
      .catch(error => {
        Swal.fire({
          position: 'top-end',
          icon: 'info',
          title: 'You need to be signed in for that too work.',
          showConfirmButton: false,
          timer: 2000
        })
      });
});
  const saveProject = document.getElementById('saveProject');
  saveProject.addEventListener("click", function() {
    userAuthState()
      .then(data => {
        downloadProject(editor, localStorage.getItem("ccProjectID"));
      })
      .catch(error => {
        downloadProjectFromLocal(editor, localStorage.getItem("ccProjectID"));
        Swal.fire({
          position: 'top-end',
          icon: 'success',
          title: "If you're signed in you can do other stuff like save it to the cloud and add a title and description.",
          showConfirmButton: false,
          timer: 2000
        })
      });
  });
  const getHelp = document.getElementById('getHelp');
  getHelp.addEventListener("click", function() {
    startTour();
  });
  const saveDataSideMenu = document.getElementById('saveDataSideMenu');
  saveDataSideMenu.addEventListener("click", async function() {
    userAuthState()
      .then(data => {
        // Get the project ID
        const id = localStorage.getItem('ccProjectID');
        updateProjectData(id, editor);
      })
      .catch(error => {
        Swal.fire({
          position: 'top-end',
          icon: 'info',
          title: 'You need to be signed in for that too work.',
          showConfirmButton: false,
          timer: 2000
        })
      });
  });
  const saveTemplate = document.getElementById('saveTemplate');
  saveTemplate.addEventListener("click", function() {
    Swal.fire({
      position: 'top-end',
      icon: 'info',
      title: 'Coming soon!',
      text: 'This feature will be coming soon. Thank you for your patience.',
      showConfirmButton: false,
      timer: 2000
    })
  });
  getProjectDataForEditor(editor);
  getThumbnail();
  // start the onboarding action
  let x = localStorage.getItem("ccProjectID");
  if(x === 'DEMO') {
    startTour();
  }
//This set the home page name to index
const pageManager = editor.Pages;
let pages =  pageManager.getAll();
console.log(pages.length);
let indexPage = pages[0];
indexPage.setName('index');
  }


checkAuthContent();
AOS.init();
