import  structure  from './structure.js';
import traits from '../traits';
import { editor } from '../editor.js';



export default (editor, config = {}) => {
  const domc = editor.DomComponents;
  const defaultType = domc.getType('default');
  const defaultModel = defaultType.model;
  const defaultView = defaultType.view;


  // Create a new component <body>
  domc.addType('form', {
    // Make the editor understand when to bind `body`
    isComponent: el => el.tagName === 'FORM',
    model: {
      defaults: {
        tagName: 'form',
        draggable: true, // Can be dropped only inside `form` elements
        droppable: true, // Can't drop other elements insidd
      },
    },
    view: {},
  });



  // Create a new component <body>
  domc.addType('link', {
    // Make the editor understand when to bind `body`
    isComponent: el => el.tagName === 'A',
    model: {
      defaults: {
        tagName: 'a',
        draggable: true, // Can be dropped only inside `form` elements
        droppable: true, // Can't drop other elements insidd
        style: {},
        stylable: ['height', 'background', 'background-color', 'font-family', 'font-size', 'font-weight', 'letter-spacing', 'color', 'line-height', 'text-align', 'text-shadow'],
      },
    },
    view: {},
  });



  // Create a new component <button>
  domc.addType('button', {
    // Make the editor understand when to bind `my-input-type`
    isComponent: el => el.tagName === 'button',

    // Model definition
    model: {
      // Default properties
      defaults: {
        tagName: 'button',
        draggable: true, //////
        droppable: false, // Can't drop other elements inside
        resizable: true,
        style: {
          width: 'auto',
          'min-width': '50px',
          height: 'auto',
          'min-height': '50px',
          'font-size': '18px',
          color: 'white',
          background: 'solid',
          'background-color': 'black',
          margin: '5px',
          'padding-right': '20px',
          'padding-left': '20px',
          'padding-top': '5px',
          'padding-bottom': '5px',
        },
        content: 'Call to action',
        styles: {},
        stylable: ['height', 'background', 'background-color', 'font-family', 'font-size', 'font-weight', 'letter-spacing', 'color', 'line-height', 'text-align', 'text-shadow'],
        'stylable-require': ['height', 'background'],
        unstylable: ['display', 'flex-direction', 'flex-wrap', 'order', 'flex-basis', 'flex-grow', 'flex-shrink', 'align-self', 'align-content', 'float', 'position', 'top', 'right', 'left', 'bottom',  'transition', 'transform'],
        traits: [
          // Strings are automatically converted to text types
          'name', // Same as: { type: 'text', name: 'name' }
          'placeholder',
          'form',
          'form action',
          'form',
          {
            type: 'input', // Type of the trait
            label: 'Form Action', // The label you will see in Settings
            name: 'formaction', // The name of the attribute/property to use on component
          },
          {
            type: 'select', // Type of the trait
            label: 'Form Method', // The label you will see in Settings
            name: 'formmethod', // The name of the attribute/property to use on component
            options: [
              { id: 'get', name: 'Get'},
              { id: 'post', name: 'Post'},
            ]
          },
          {
            type: 'select', // Type of the trait
            label: 'Form Target', // The label you will see in Settings
            name: 'formtarget', // The name of the attribute/property to use on component
            options: [
              { id: '_blank', name: 'Blank'},
              { id: '_self', name: 'Self'},
              { id: '_parent', name: 'Parent'},
              { id: '_top', name: 'Top'},
            ]
          },
          {
            type: 'select', // Type of the trait
            label: 'Autofocus', // The label you will see in Settings
            name: 'autofocus', // The name of the attribute/property to use on component
            options: [
              { id: 'true', name: 'True'},
              { id: 'false', name: 'False'},
            ]
          },
          {
            type: 'select', // Type of the trait
            label: 'Type', // The label you will see in Settings
            name: 'type', // The name of the attribute/property to use on component
            options: [
              { id: 'button', name: 'Button'},
              { id: 'reset', name: 'Reset'},
              { id: 'submit', name: 'Submit'},
            ]
          },
           {
            type: 'checkbox',
            name: 'required',
        }],
        // As by default, traits are binded to attributes, so to define
        // their initial value we can use attributes
        attributes: { type: 'text', required: true },



      }
    }
  });
  // Create a new component <cell>
  domc.addType('line', {
    // Make the editor understand when to bind `my-input-type`
    isComponent: el => el.tagName === 'span',

    // Model definition
    model: {
      // Default properties
      defaults: {
        tagName: 'span',
        attributes: {},
        draggable: true, ////// // Can be dropped only inside `form` elements
        droppable: false,
        traits: [],
        resizable: true,
        style: {
          width: '50px',
          'min-width': '10px',
          height: '2px',
          'min-height': '1px',
          'max-height': '10px',
          background: 'solid',
          margin: 'auto',
          'background-color': 'grey',
        },
        styles: {},
        stylable: ['height', 'background'],
        'stylable-require': ['height', 'background'],
        unstylable: ['display', 'flex-direction', 'flex-wrap', 'order', 'flex-basis', 'flex-grow', 'flex-shrink', 'align-self', 'align-content', 'float', 'position', 'top', 'right', 'left', 'bottom', 'transition', 'transform','font-family', 'font-size', 'font-weight', 'letter-spacing', 'color', 'line-height', 'text-align', 'text-shadow'],

      }
    }
  });

  // Create a new component
  domc.addType('map', {
    isComponent: el => el.tagName === 'MAP',
    model: {
      defaults: {
        draggable: true, // Can be dropped only inside
        droppable: true, // Can't drop other elements insidd
      },
    },
    view: {},
  });

  // Create a new component
  domc.addType('image', {
    isComponent: el => el.tagName === 'IMG',
    model: {
      defaults: {
        draggable: true, // Can be dropped only inside
        droppable: true, // Can't drop other elements insidd
      },
    },
    view: {},
  });


// Create this new compent section
// Update component
domc.addType('link', {
  model: {
    defaults: {
      traits: [
        {
          type: 'href-next',
          name: 'href',
          label: 'New href',
        },
      ]
    }
  }
});




}
