import { DataStore } from '@aws-amplify/datastore';
import { Website} from '../models';
import { showWebsites } from './website-query.js';
// Amplify
import {Amplify, Auth, Storage } from "aws-amplify";
import Swal from 'sweetalert2';

export const createwebsite = async (title, description, boolean) => {

  await DataStore.save(
    new Website({
		"title": title,
		"description": description,
		"status": boolean,
    "template": false,
    "s3key": "",
	})
);
showWebsites();

}
