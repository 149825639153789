/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-west-2",
    "aws_cognito_identity_pool_id": "us-west-2:07fae960-f50c-4631-854f-b434d390655f",
    "aws_cognito_region": "us-west-2",
    "aws_user_pools_id": "us-west-2_mOUsPwAYW",
    "aws_user_pools_web_client_id": "66i04b521mh3s2shndacla70qc",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_appsync_graphqlEndpoint": "https://3cp3fuwn75ellcwbahjugdtueu.appsync-api.us-west-2.amazonaws.com/graphql",
    "aws_appsync_region": "us-west-2",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_appsync_apiKey": "da2-sksrbzugynewfoclzbhgxcpu5y",
    "aws_user_files_s3_bucket": "codecasually6673a4157edf48db8387761a2b4fc363183805-live",
    "aws_user_files_s3_bucket_region": "us-west-2"
};


export default awsmobile;
