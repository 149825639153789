import { Amplify, Storage, Auth } from 'aws-amplify';
import { DataStore, Predicates, SortDirection } from '@aws-amplify/datastore';
import { showWebsites } from './website-query.js';
import Swal from 'sweetalert2';
import { Website, Template } from '../models';
import aws_exports from "../aws-exports.js";
Amplify.configure(aws_exports);
import grapesjs from 'grapesjs';
import editor from '../editor.js';



export const updateWebsite = async (websiteID, titleData, descriptionData, statusData, templateData) => {
  let id = websiteID;
  let title = titleData;
  let description = descriptionData;
  let status = statusData;
  let template = templateData
  let original = await DataStore.query(Website, id);

  await DataStore.save(
    Website.copyOf(original, updated => {
      updated.title = title;
      updated.description = description;
      updated.status = status;
      updated.template = templateData;
    }))
  showWebsites();
};

export const updateProjectData = async (websiteID, editor) => {
  Swal.fire({
    backdrop: `
      rgba(0, 0, 0, 0.3)
    `,
    position: 'top-end',
    icon: 'info',
    title: "We are in the process of saving this project to AWS.",
    text: "We'll let you know when it's done. Please stay on the page.",
    showConfirmButton: false,
    timer: 4000
  });
  let original = await DataStore.query(Website, websiteID);
  // Get project data before removing assets
  let completeData = editor.getProjectData();
  // Save the assets in storage
  const s3storage = await Storage.put(websiteID, JSON.stringify(completeData));
  // stringify the s3StorageKey object
  let s3key = JSON.stringify(s3storage);
  await DataStore.save(
    Website.copyOf(original, updated => {
      updated.title = original.title;
      updated.description = original.description;
      updated.status = original.status;
      updated.s3key = s3key;
      updated.template = original.template;
    }));

    setTimeout(() => {
      // Send the alert that everything is done.
      Swal.fire({
        backdrop: `
          rgba(0, 0, 0, 0.3)
        `,
        position: 'top-end',
        icon: 'success',
        title: "The website has been safely stored in the cloud.",
        text: "It is safe to leave the page.",
        showConfirmButton: false,
        timer: 2000
      });
}, "2500")

};


export const updateThumbnail = async (value) => {
    let iD = localStorage.getItem("ccProjectID");
    let original = await DataStore.query(Website, iD);
    let x = original.template;
    let image = value;
    const s3storage = await Storage.put(`thumbnail${iD}`, image);
    let s3key = JSON.stringify(s3storage);
      await DataStore.save(
        Website.copyOf(original, updated => {
          updated.title = original.title;
          updated.description = original.description;
          updated.status = original.status;
          updated.s3key = original.s3key;
          updated.template = true;
          updated.thumbnail = s3key;
        }));

    Swal.fire({
      backdrop: `
        rgba(0, 0, 0, 0.3)
      `,
      position: 'top-end',
      icon: 'success',
      title: "The website has been updated.",
      showConfirmButton: false,
      timer: 2000
    });
};


export const getThumbnail = async () => {
  let iD = localStorage.getItem("ccProjectID");
  const storageData = await Storage.get(`thumbnail${iD}`, { download: true });
  storageData.Body.text().then(imageData => {
    const thumbnailImage = document.getElementById("thumbnailImage");
    thumbnailImage.src = imageData;
    location.reload;
  });
  console.log(`The get thumbnail function has completed.`);
}
/*
  let db; // Open a db object
  const request = indexedDB.open("cc");
  request.onerror = (event) => { // Handle errors
    console.error(`Database error: ${event.target.errorCode}`);
  };
  request.onsuccess = (event) => {
    db = event.target.result;
    const transaction = db.transaction(["projects"], "readwrite"); // Start a transaction *Magic Time*
    const objectStore = transaction.objectStore("projects");
    const request = objectStore.get("ccProject"); // Get all the project Data
    request.onerror = (event) => { // Deal with any errors
      console.error(`Database error: ${event.target.errorCode}`);
    };
    request.onsuccess = (event) => { // Save the project to index DB
      let data = event.target.result;
      data = JSON.parse(projectData);
      // Put this updated object back into the database.
      const requestUpdate = objectStore.put(data, "ccProject");
      requestUpdate.onerror = (event) => {
        console.error(`Database error: ${event.target.errorCode}`);
      };
      requestUpdate.onsuccess = (event) => {
        console.log("Success! The project data has been udpated to `This is here now`.");
      };
    };
    transaction.oncomplete = (event) => { // Do something when all the data is added to the database.
      console.log("All done!");
    };
    transaction.onerror = (event) => {
      console.error(`Database error: ${event.target.errorCode}`);
    };
  };
  */
