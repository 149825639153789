import {  updateWebsite } from '../api/website-update.js';
import grapesjs from 'grapesjs';
import '../editor.js';
import { editor } from '../editor.js';
import { Amplify, Auth, Storage } from "aws-amplify";
import {  DataStore,  Predicates,  SortDirection } from '@aws-amplify/datastore';
import { Website } from '../models';
import aws_exports from "../aws-exports.js";
Amplify.configure(aws_exports);
import Swal from 'sweetalert2'


export const transferProjectToEdit = async (websiteID) => {
  const id = websiteID; // get ID
  if (!id) {
    Swal.fire({
      backdrop: `
          rgba(0, 0, 0, 0.3)
        `,
      position: 'top-end',
      icon: 'error',
      title: "This website could not be found.",
      showConfirmButton: false,
      timer: 2000
    });
  };
  localStorage.setItem('ccProjectID', id);
  window.location.pathname = "/editor.html";
};



export const getProjectDataForEditor = async (editor) => {
  const id = localStorage.getItem('ccProjectID');
  if (!id) {
    Swal.fire({
      backdrop: `
          rgba(0, 0, 0, 0.3)
        `,
      position: 'top-end',
      icon: 'error',
      title: "This website could not be found.",
      showConfirmButton: false,
      timer: 2000
    });
    window.location = '/index.html';

} else if (id === "DEMO") {

  // Adds new page.
  const pageManager = editor.Pages;
  let pages =  pageManager.getAll();
  console.log(pages.length);
  let indexPage = pages[0];
  indexPage.setName('index');
/*
  pageManager.add({
   id: 'contact', // without an explicit ID, a random one will be created
   styles: `.my-class { color: red }`, // or a JSON of styles
   component: [], // or a JSON of components
  });
*/

} else {
  const website = await DataStore.query(Website, id); // get website from ID
  // Get data from storage
  const storageData = await Storage.get(id, { download: true });
  storageData.Body.text().then(projectData => {
    if (!projectData) { // If there is no project data
      Swal.fire({
        backdrop: `
            rgba(0, 0, 0, 0.3)
          `,
        position: 'top-end',
        icon: 'error',
        title: "No project data was found.",
        showConfirmButton: false,
        timer: 2000
      });a
    } else if (projectData === "undefined") {
      localStorage.setItem('ccProjectID', id);
      // Check if the browser supports indexDB
      if (!window.indexedDB) {
        console.log("Your browser doesn't support a stable version of IndexedDB. Such and such feature will not be available.");
      } else {
        let db; // Open a db object
        const request = indexedDB.open("cc");
        request.onerror = (event) => { // Handle errors
          console.error(`Database error: ${event.target.errorCode}`);
        };
        request.onsuccess = (event) => {
          db = event.target.result;
          const transaction = db.transaction(["projects"], "readwrite"); // Start a transaction *Magic Time*
          const objectStore = transaction.objectStore("projects");
          const request = objectStore.get("ccProject"); // Get all the project Data
          request.onerror = (event) => { // Deal with any errors
            console.error(`Database error: ${event.target.errorCode}`);
          };
          request.onsuccess = (event) => { // Save the project to index DB
            let data = event.target.result;
            data = "";
            // Put this updated object back into the database.
            const requestUpdate = objectStore.put(data, "ccProject");
            requestUpdate.onerror = (event) => {
              console.error(`Database error: ${event.target.errorCode}`);
            };
            requestUpdate.onsuccess = (event) => {
              console.log("Success! The project data has been udpated to `This is here now`.");
            };
            console.log(JSON.stringify(request));
          };
          transaction.oncomplete = (event) => { // Do something when all the data is added to the database.
            console.log("All done!");
          };
          transaction.onerror = (event) => {
            console.error(`Database error: ${event.target.errorCode}`);
          };
        };
        localStorage.setItem('ccProjectID', id);
      window.location = '/editor.html';
      };
    } else {
      let db; // Open a db object
      const request = indexedDB.open("cc");
      request.onerror = (event) => { // Handle errors
        console.error(`Database error: ${event.target.errorCode}`);
      };
      request.onsuccess = (event) => {
        db = event.target.result;
        const transaction = db.transaction(["projects"], "readwrite"); // Start a transaction *Magic Time*
        const objectStore = transaction.objectStore("projects");
        const request = objectStore.get("ccProject"); // Get all the project Data
        request.onerror = (event) => { // Deal with any errors
          console.error(`Database error: ${event.target.errorCode}`);
        };
        request.onsuccess = (event) => { // Save the project to index DB
          let data = event.target.result;
          data = JSON.parse(projectData);
          // Put this updated object back into the database.
          editor.loadProjectData(data);
          const requestUpdate = objectStore.put(data, "ccProject");
          requestUpdate.onerror = (event) => {
            console.error(`Database error: ${event.target.errorCode}`);
          };
          requestUpdate.onsuccess = (event) => {
            console.log("Success! The project data has been udpated to `This is here now`.");
          };
          console.log(JSON.stringify(request));
        };
        transaction.oncomplete = (event) => { // Do something when all the data is added to the database.
          console.log("All done!");
        };
        transaction.onerror = (event) => {
          console.error(`Database error: ${event.target.errorCode}`);
        };
      };
    };
  });
  storageData.Body.text().catch(error => {
    Swal.fire({
      backdrop: `
          rgba(0, 0, 0, 0.3)
        `,
      position: 'top-end',
      icon: 'error',
      title: "There was no storage found but we'll create some when you save.",
      showConfirmButton: false,
      timer: 2000
    });
    // Save the assets in storage
    // const s3storage = await Storage.put(id, "");
    let projectData = "";
    let db; // Open a db object
    const request = indexedDB.open("cc");
    request.onerror = (event) => { // Handle errors
      console.error(`Database error: ${event.target.errorCode}`);
    };
    request.onsuccess = (event) => {
      db = event.target.result;
      const transaction = db.transaction(["projects"], "readwrite"); // Start a transaction *Magic Time*
      const objectStore = transaction.objectStore("projects");
      const request = objectStore.get("ccProject"); // Get all the project Data
      request.onerror = (event) => { // Deal with any errors
        console.error(`Database error: ${event.target.errorCode}`);
      };
      request.onsuccess = (event) => { // Save the project to index DB
        let data = event.target.result;
        data = projectData;
        // Put this updated object back into the database.
        const requestUpdate = objectStore.put(data, "ccProject");
        requestUpdate.onerror = (event) => {
          console.error(`Database error: ${event.target.errorCode}`);
        };
        requestUpdate.onsuccess = (event) => {
          console.log("Success! The project data has been udpated to `This is here now`.");
        };
        console.log(JSON.stringify(request));
      };
      transaction.oncomplete = (event) => { // Do something when all the data is added to the database.
        console.log("All done!");
      };
      transaction.onerror = (event) => {
        console.error(`Database error: ${event.target.errorCode}`);
      };
    };
  });
}
};



export const clearIndexDB = async () => {
  let db; // Open a db object
  const request = indexedDB.open("cc");
  request.onerror = (event) => { // Handle errors
    console.error(`Database error: ${event.target.errorCode}`);
  };
  request.onsuccess = (event) => {
    db = event.target.result;
    const transaction = db.transaction(["projects"], "readwrite"); // Start a transaction *Magic Time*
    const objectStore = transaction.objectStore("projects");
    const request = objectStore.get("ccProject"); // Get all the project Data
    request.onerror = (event) => { // Deal with any errors
      console.error(`Database error: ${event.target.errorCode}`);
    };
    request.onsuccess = (event) => { // Save the project to index DB
      let data = event.target.result;
      data = "";
      // Put this updated object back into the database.
      const requestUpdate = objectStore.put(data, "ccProject");
      requestUpdate.onerror = (event) => {
        console.error(`Database error: ${event.target.errorCode}`);
      };
      requestUpdate.onsuccess = (event) => {
        console.log("Success! The project data has been udpated to `This is here now`.");
      };
      console.log(JSON.stringify(request));
    };
    transaction.oncomplete = (event) => { // Do something when all the data is added to the database.
      console.log("All done!");
    };
    transaction.onerror = (event) => {
      console.error(`Database error: ${event.target.errorCode}`);
    };
  };
};
