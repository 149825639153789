import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import grapesjs from 'grapesjs';
import '../editor.js';
// aws
// Amplify
import Amplify from "aws-amplify";
import {  Auth } from 'aws-amplify';
import aws_exports from "../aws-exports.js";
import { Website} from '../models';
import {
  DataStore,
  Predicates,
  SortDirection
} from '@aws-amplify/datastore';




export const downloadProject = async (ed, id) => {
  const pm = ed.Pages; // Gets Page Manager
  const pages = pm.getAll(); // Gets Pages
  const zip = new JSZip(); // Create a zip file
  const website = await DataStore.query(Website, id); // get website from ID
  // zip.file("Hello.txt", "Hello World\n");
  pages.forEach((Page) => {
    // Send the page to the editor so you can get the things
    pm.select(Page);
    // Css and html document inner content
    const css = ed.getCss();
    const html = ed.getHtml();
    const js = ed.getJs();
    // Dynamically named document variables
    const htmlDocName = Page.getName() + ".html";
    const cssDocName = Page.getName() + ".css";
    const jsDocName = Page.getName() + ".js";
    // Create the HTML doc
    zip.file(`${website.title}/${htmlDocName}`, `<!DOCTYPE html>
    <html lang="en">
    <head>
        <meta charset="utf-8">
        <meta name="viewport" content="width=device-width, initial-scale=1">
        <!--SEO -->
        <meta name="title" content="${website.title}">
        <meta name="description" content="${website.description}">
        <!-- Style -->
        <link rel="stylesheet" href="styles/${cssDocName}">
    </head>
    ${html}
    </html>`);
    // Create the css doc
    zip.file(`${website.title}/styles/${cssDocName}`, css);
    // Create the JS doc
    zip.file(`${website.title}/scripts/${jsDocName}`, js);
  });
  // Zip and save the bundle
  zip.generateAsync({
      type: "blob"
    })
    .then(function(content) {
      // see FileSaver.js
      saveAs(content, `${website.title}.zip`);
    });
};

export const downloadProjectFromLocal = async (ed, id) => {
  const pm = ed.Pages; // Gets Page Manager
  const pages = pm.getAll(); // Gets Pages
  const zip = new JSZip(); // Create a zip file
  // zip.file("Hello.txt", "Hello World\n");
  pages.forEach((Page) => {
    // Send the page to the editor so you can get the things
    pm.select(Page);
    // Css and html document inner content
    const css = ed.getCss();
    const html = ed.getHtml();
    const js = ed.getJs();
    // Dynamically named document variables
    const htmlDocName = Page.getName() + ".html";
    const cssDocName = Page.getName() + ".css";
    const jsDocName = Page.getName() + ".js";
    // Create the HTML doc
    zip.file(`code_casually/${htmlDocName}`, `<!DOCTYPE html>
    <html lang="en">
    <head>
        <meta charset="utf-8">
        <meta name="viewport" content="width=device-width, initial-scale=1">
        <!--SEO -->
        <meta name="title" content="This is your website title.">
        <meta name="description" content="This is your website description.">
        <!-- Style -->
        <link rel="stylesheet" href="styles/${cssDocName}">
    </head>
    ${html}
    </html>`);
    // Create the css doc
    zip.file(`code_casually/styles/${cssDocName}`, css);
    // Create the JS doc
    zip.file(`code_casually/scripts/${jsDocName}`, js);
  });
  // Zip and save the bundle
  zip.generateAsync({
      type: "blob"
    })
    .then(function(content) {
      // see FileSaver.js
      saveAs(content, `code_casually.zip`);
    });
};
