import pluginNavbar from '../navbar';


export default (editor, config = {}) => {
  const domc = editor.DomComponents;
  const defaultType = domc.getType('default');
  const defaultModel = defaultType.model;
  const defaultView = defaultType.view;


  // Create a new component <body>
  domc.addType('body', {
    // Make the editor understand when to bind `body`
    isComponent: el => el.tagName === 'body',
    model: {
      defaults: {
        tagName: 'body',
        draggable: false, // Can be dropped only inside `form` elements
        droppable: true, // Can't drop other elements insidd
        stylable: ['background', 'background-color'],
        attributes: {
          class: 'body'
        },
        components: '',
        style: {},
      },
    },
    view: {},
  });


  // Create a new component <body>
  domc.addType('default', {
    // Make the editor understand when to bind `body`
    isComponent: el => el.tagName === 'DIV',
    model: {
      defaults: {
        tagName: 'div',
        draggable: true, // Can be dropped only inside `form` elements
        droppable: true, // Can't drop other elements insidd
      },
    },
    view: {},
  });


  // Create a new component <body>
  domc.addType('navbar', {
    // Make the editor understand when to bind `body`
    isComponent: el => el.tagName === 'DIV',
    model: {
      defaults: {
        tagName: 'div',
        draggable: true, // Can be dropped only inside `form` elements
        droppable: true, // Can't drop other elements insidd
      },
    },
    view: {},
  });



  // Create a new component <body>
  domc.addType('countdown', {
    // Make the editor understand when to bind `body`
    isComponent: el => el.tagName === 'DIV',
    model: {
      defaults: {
        tagName: 'div',
        draggable: true, // Can be dropped only inside `form` elements
        droppable: true, // Can't drop other elements insidd
      },
    },
    view: {},
  });



  // Create a new component <frame>
  domc.addType('section-wrapper', {
    // Make the editor understand when to bind `my-input-type`
    isComponent: el => el.tagName === 'SECTION',

    // Model definition
    model: {
      // Default properties
      defaults: {
        tagName: 'section',
        draggable: true, // Can be dropped only inside ...
        droppable: true, // Can't drop other elements inside
        traits: [
          'id',
          'class',
        ],
        attributes: {
          type: 'text',
          required: true
        },
        style: {
          width: '100%',
          height: 'auto',
          'min-height': '300px',
          background: 'none',
          padding: '0px',
          margin: 'auto',
          display: 'flex',
          overflow: 'hidden',
          display: 'flex',
          'justify-content': 'safe center',
          'flex-direction': 'row',
          'align-items': 'flex-start',
          'flex-wrap': 'wrap',
        },
        stylable: ['height', 'flex-direction', 'flex-wrap', 'flex-basis', 'flex-grow', 'flex-shrink', 'align-items', 'justify-content', ],
        'stylable-require': ['height', 'background'],
        unstylable: ['width', 'max-width', 'min-width', 'display', 'border', 'border-radius', 'align-content', 'float', 'position', 'top', 'right', 'left', 'bottom', 'margin', 'transition', 'transform', 'box-shadow', 'font-family', 'font-size', 'font-weight', 'letter-spacing', 'color', 'line-height', 'text-align', 'text-shadow'],
      }
    }
  });



  // Create a new component <cell>
  domc.addType('flex-box', {
    // Make the editor understand when to bind `my-input-type`
    isComponent: el => el.tagName === 'main',

    // Model definition
    model: {
      // Default properties
      defaults: {
        tagName: 'main',
        attributes: {},
        draggable: true, // Can be dropped only inside `form` elements
        resizable: true,
        style: {
          'max-height': 'auto',
          background: 'solid',
          padding: '0px',
          width: '250px',
          'max-width': 'auto',
          height: '250px',
          'max-height': 'auto',
          background: 'solid',
          display: 'flex',
          margin: '0px',
          overflow: 'hidden',
          'justify-content': 'safe center',
          'flex-direction': 'column',
          'align-items': 'center',
        },
        styles: {},
        stylable: ['height', 'width', 'background', 'min-width', 'max-height'],
        'stylable-require': ['height', 'background'],
        unstylable: ['display', 'font-family', 'font-size', 'font-weight', 'letter-spacing', 'color', 'line-height', 'text-align', 'text-shadow'],
        traits: [
          'id',
        ],
        attributes: {
          type: 'text',
          required: true
        },
      }
    }
  });



// Create a new component 'overlay'
  editor.DomComponents.addType('overlay', {
    // Make the editor understand when to bind `my-input-type`
    isComponent: el => el.tagName === 'div',

    // Model definition
    // Model definition
    model: {
      // Default properties
      defaults: {
        tagName: 'div',
        attributes: {},
        selectable: true,
        draggable: false,
        droppable: false,
        removable: false,
        copyable: false,
        style: {
          'width': '100%',
          'min-width': '100%',
          'max-width': '100%',
          'height': '100%',
          'min-height': '100%',
          'max-height': '100%',
          'display': 'flex',
          'flex-direction': 'column',
          'align-items': 'center',
          'justify-content': 'center',
          'background': 'solid',
          'background-color': 'rgba(0, 0, 0, 0.4)',
        },
        styles: {},
        stylable: ['background-color'],
        'stylable-require': ['background-color'],
        unstylable: ['float', 'position', 'top', 'right', 'left', 'bottom', 'min-height', 'border-radius', 'box-shadow', 'background', 'opacity', 'transition', 'transform',   'order', 'align-self', 'display', 'max-width', 'max-height', 'margin', 'padding', 'font-family', 'font-size', 'font-weight', 'letter-spacing', 'font-family', 'font-size', 'font-weight', 'letter-spacing', 'color', 'line-height', 'text-align', 'text-shadow', 'height', 'width', 'margin', 'padding', 'flex', 'border'],
        traits: [
          'id',
        ],
      }
    }
  });

// Update all component model default properties






}
