import { DataStore } from '@aws-amplify/datastore';
import { Website } from '../models';
import { Amplify, Auth, Storage } from "aws-amplify";
import { showWebsites } from './website-query.js';
import Swal from 'sweetalert2';


export const deleteItem = async (id) => {

  const modelToDelete = await DataStore.query(Website, id);
  DataStore.delete(modelToDelete);
  setTimeout(() => {
  showWebsites();
}, 1500)
  Swal.fire({
    backdrop: `
      rgba(0, 0, 0, 0.3)
    `,
    position: 'top-end',
    icon: 'success',
    title: "This website has been deleted.",
    showConfirmButton: false,
    timer: 2000
  });
  await Storage.remove(id);
};
